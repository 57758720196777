.home-page {
  min-height: 100vh;

  .head-section {
    background-size: 130%;
    background-repeat: no-repeat;
    background-position: 22% 100%;
    &-wrapper {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 56px;
        margin-bottom: 38px;
        line-height: 54px;
        letter-spacing: -3.64px;
        font-weight: $medium;
      }

      > p {
        margin: 0;
        font-size: 19px;
        p {
          margin: 0;
          letter-spacing: -0.19px;

          &:last-child {
            margin-top: 30px;
          }
        }
      }
      a {
        margin-top: 43px;
        min-height: 40px;
        color: $green;
        display: flex;
        align-items: baseline;
        // padding-bottom: 160px;
        padding-bottom: 0px;
        text-decoration: none;
        font-family: $familyG;
        font-weight: $medium;
        p {
          margin: 0;
          position: relative;
          margin-right: 15px;
          font-size: 22px;
          line-height: 17px;
          letter-spacing: -1.1px;

          &:after {
            width: 100%;
            content: '';
            position: absolute;
            height: 2px;
            background-color: $grey;
            transition: background-color 0.3s ease, bottom 0.3s ease;
            bottom: -10px;
            left: 0;
          }
        }
        &:hover {
          p {
            &:after {
              background-color: $green;
              bottom: -7px;
            }
          }
        }
        img {
          width: 5px;
          height: 14px;
        }
      }
    }
  }
  .mission-section {
    padding-top: 62px;
    padding-bottom: 62px;
    &-wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .left {
        text-align: center;
        h1 {
          font-size: 43px;
          line-height: 42px;
          font-weight: $medium;
          margin-bottom: 37px;
          letter-spacing: -2.79px;
          margin-top: 0;
        }
        p {
          margin: 0;
          margin-bottom: 37px;
          font-size: 19px;
          letter-spacing: -0.19px;
          line-height: 26px;
        }
      }
      a {
        font-size: 22px;
        color: $green;
        display: flex;
        align-items: baseline;
        text-decoration: none;
        justify-content: center;
        font-family: $familyG;
        font-weight: $medium;
        p {
          margin: 0;
          position: relative;
          margin-right: 15px;
          font-size: 22px;

          &:after {
            width: 100%;
            content: '';
            position: absolute;
            height: 2px;
            background-color: $grey;
            transition: background-color 0.3s ease, bottom 0.3s ease;
            bottom: -10px;
            left: 0;
          }
        }
        &:hover {
          p {
            &:after {
              background-color: $green;
              bottom: -2px;
            }
          }
        }
        img {
          width: 5px;
          height: 14px;
        }
      }
    }
  }
  .game-section {
    background: linear-gradient(
      150deg,
      rgba(0, 0, 0, 0.04) 0%,
      rgba(255, 255, 255, 1) 34%
    );
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      h1 {
        font-size: 41px;
        font-weight: $medium;
        line-height: 41px;
        margin-bottom: 38px;
        letter-spacing: -2.05px;
        margin-top: 80px;
      }
      p {
        margin: 0;
        font-size: 19px;
        letter-spacing: -0.19px;
        line-height: 26px;
      }
      a {
        margin-top: 31px;
        font-size: 22px;
        color: $green;
        display: flex;
        align-items: baseline;
        padding-bottom: 60px;
        text-decoration: none;
        font-family: $familyG;
        font-weight: $medium;
        p {
          margin: 0;
          position: relative;
          margin-right: 15px;
          font-size: 22px;
          width: 100%;
          &:after {
            width: 100%;
            content: '';
            position: absolute;
            height: 2px;
            background-color: $grey;
            transition: background-color 0.3s ease, bottom 0.3s ease;
            bottom: -10px;
            left: 0;
          }
        }
        &:hover {
          p {
            &:after {
              background-color: $green;
              bottom: -2px;
            }
          }
        }
        img {
          width: 5px;
          height: 14px;
        }
      }
    }
  }
  .company-section {
    display: flex;
    flex-wrap: wrap;
    &-item {
      margin: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
      }
    }
    &-desc {
      display: none;
    }
  }
  .team-section {
    background: linear-gradient(
      203deg,
      rgba(0, 0, 0, 0.04) 0%,
      rgba(255, 255, 255, 1) 34%
    );
    border-bottom: 6px solid $green;
    &-wrapper {
      width: unset !important ;
      margin: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      text-align: center;
      padding-bottom: 223px;
      padding-top: 50px;

      span {
        display: block;
        width: 295px;
        margin-right: auto;
        margin-left: auto;
        h1 {
          font-size: 70px;
          line-height: 51px;
          letter-spacing: -3.5px;
          margin: 0;
          font-weight: $medium;
        }
        h2 {
          margin: 0;
          margin-bottom: 50px;
          font-size: 26px;
          line-height: 45px;
          letter-spacing: -1.3px;
          font-weight: $medium;
        }
      }
      a {
        font-size: 22px;
        color: $green;
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding-bottom: 60px;
        text-decoration: none;
        font-family: $familyG;
        font-weight: $medium;
        p {
          margin: 0;
          position: relative;
          margin-right: 15px;
          font-size: 22px;

          &:after {
            width: 100%;
            content: '';
            position: absolute;
            height: 2px;
            background-color: $grey;
            transition: background-color 0.3s ease, bottom 0.3s ease;
            bottom: -10px;
            left: 0;
          }
        }
        &:hover {
          p {
            &:after {
              background-color: $green;
              bottom: -2px;
            }
          }
        }
        img {
          width: 5px;
          height: 14px;
        }
      }
    }
  }

  .latest-news-section {
    width: 295px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 85px;
    font-family: $familyG;
    &-heading {
      margin: 60px 0 18px;
      font-weight: $medium;
      font-size: 39px;
      line-height: 46px;
      letter-spacing: -1.7px;
      text-align: center;
      border-bottom: 3px solid $orange;
      padding-bottom: 8px;
    }
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 28px;
    }
    &-wrapper {
      display: flex;
      margin-bottom: 17px;
      text-decoration: none;
      color: $black;
      border-bottom: 2px solid $grey;
      padding-bottom: 19px;
    }
    img {
      width: 30%;
      margin-right: 13px;
    }
    &-content {
      width: 82%;
      text-decoration: none;
      &-date {
        font-size: 11px;
        margin: 0;
        margin-bottom: 5px;
        line-height: 16px;
      }
      &-desc {
        font-size: 17px;
        line-height: 19px;
        margin: 0;
      }
    }
    .load-more-content {
      text-align: center;
      position: relative;
      &-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: transparent
          linear-gradient(180deg, #ffffff00 0%, #ffffff7b 39%, #ffffff 100%) 0%
          0% no-repeat padding-box;
        top: -95px;
        z-index: 1;
      }
      span {
        border-bottom: 2px solid $black;
        padding-bottom: 10px;
        display: inline-block;
        margin-bottom: 100px;
      }
    }
  }
}
@media only screen and (min-width: $desktop) {
  .home-page {
    .head-section {
      border-bottom: 4px solid $orange;
      background-position: bottom right;
      background-size: auto 45%;
      background-repeat: no-repeat;
      min-height: 60vh;

      &-wrapper {
        h1 {
          font-size: 72px;
          margin-bottom: 22px;
          line-height: 68px;
          letter-spacing: -4.68px;
        }
        > p {
          width: 40%;
          margin: 0;
          p {
            margin: 0;
            line-height: 26px;
            font-weight: $light;
            &:last-child {
              margin-top: 30px;
            }
          }
        }
        a {
          margin-top: 43px;
          // padding-bottom: 310px;
          padding-bottom: 0px;
          img {
            width: 5px;
            height: 14px;
          }
        }
      }
    }
    .mission-section {
      padding-top: 60px;
      padding-bottom: 75px;
      &-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: row;
        .left {
          width: 48.5%;
          text-align: left;
          h1 {
            font-size: 60px;
            line-height: 56px;
            margin-bottom: 18px;
            margin-top: 0;
            letter-spacing: -3.9px;
          }
          p {
            margin: 0;
            p {
              font-weight: $light;
            }
          }
        }
        a {
          p {
            margin-right: 25px;
            margin-bottom: 0;
            margin-top: 0;
          }
          img {
            width: 5px;
            height: 14px;
          }
        }
      }
    }
    .game-section {
      background: linear-gradient(
        150deg,
        rgba(0, 0, 0, 0.04) 0%,
        rgba(255, 255, 255, 1) 34%
      );
      &-wrapper {
        padding-top: 60px;
        padding-bottom: 68px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-size: 102px;
          line-height: 93px;
          letter-spacing: -6px;
          margin-bottom: 27px;
        }
        p {
          font-size: 19px;
          line-height: 26px;
          margin: 0;
          width: 85%;
          margin-right: auto;
          margin-left: auto;
          p {
            font-weight: $light;
          }
        }

        a {
          margin-top: 35px;
          p {
            margin-right: 25px;
            margin-bottom: 0;
            margin-top: 0;
          }
          img {
            width: 5px;
            height: 14px;
          }
        }
      }
    }
    .company-section {
      display: flex;
      padding-bottom: 40px;
      &-item {
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        &:hover {
          .company-section-desc {
            opacity: 1;
            &-text {
              transform: scale(1);
            }
          }
        }
      }
      &-desc {
        opacity: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: opacity 0.3s ease-in;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: white;
        img {
          width: 35%;
          padding-top: 13px;
        }
        &-text {
          text-align: center;
          font-family: $familyG;
          font-size: 28px;
          font-weight: $medium;
          letter-spacing: -1.2px;
          line-height: 29px;
          width: 90%;
          transition: transform 0.5s ease-in-out;
          transform: scale(1.2);
        }
        .invest-date {
          font-family: $familyG;
          font-size: 18px;
          font-weight: $medium;
        }
      }
    }
    .team-section {
      height: 600px;
      &-wrapper {
        width: 941px !important;
        padding: 0;
        height: 100%;
        margin: 0 auto;
        span {
          display: unset;
          width: unset;
          margin-right: unset;
          margin-left: unset;
          h1 {
            font-size: 89px;
            line-height: 68px;
            letter-spacing: -4.45px;
            margin: 0;
            margin-bottom: 10px;
            padding-top: 80px;
            font-weight: $medium;
          }
          h2 {
            letter-spacing: -2.3px;
            font-size: 46px;
            line-height: 45px;
            margin: 0;
            font-weight: $medium;
          }
        }
        a {
          font-size: 22px;
          margin-top: 46px;
          justify-content: center;
          p {
            margin-right: 25px;
            margin-bottom: 0;
            margin-top: 0;
          }
          img {
            width: 5px;
            height: 14px;
          }
        }
      }
    }
    .latest-news-section {
      width: 941px;
      margin-right: auto;
      margin-left: auto;
      &-heading {
        margin: 0 0 18px;
        font-weight: $medium;
        font-size: 39px;
        line-height: 46px;
        letter-spacing: -1.95px;
        text-align: center;
        border-bottom: 3px solid $orange;
        padding-bottom: 17px;
      }
      &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &-wrapper {
        width: 45%;
        border-bottom: none;
        display: flex;
        margin-bottom: 17px;
        text-decoration: none;
        color: $black;
      }
      img {
        width: 18%;
        margin-right: 13px;
      }
      &-content {
        border-bottom: 2px solid $grey;
        width: 82%;
        padding-bottom: 10px;
        text-decoration: none;
        &-date {
          font-size: 11px;
          letter-spacing: -0.11px;
          margin: 0;
          margin-bottom: 5px;
          font-weight: $light;
          line-height: 16px;
        }
        &-desc {
          font-size: 17px;
          letter-spacing: -0.85px;
          font-weight: $medium;
          line-height: 19px;
          margin: 0;
        }
      }
      .load-more-content {
        text-align: center;
        position: relative;
        &-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          background: transparent
            linear-gradient(180deg, #ffffff00 0%, #ffffff7b 39%, #ffffff 100%)
            0% 0% no-repeat padding-box;
          top: -95px;
          z-index: 1;
        }
        span {
          border-bottom: 2px solid $black;
          padding-bottom: 10px;
          display: inline-block;
          margin-bottom: 100px;
        }
      }
    }
  }
}
