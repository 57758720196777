.cursor-pointer {
  cursor: pointer;
}
section {
  > div:not(.clear-spacing-mb) {
    width: 295px;
    margin-right: auto;
    margin-left: auto;
  }
}
.sticky-container {
  > div {
    transform: none !important;
  }
}

.orange-header {
  header {
    border-bottom: 2px solid $orange;
  }
}

.empty-page {
  min-height: 60vh;
}

body {
  font-family: $familyB;
  padding-top: 93px;
  min-height: 100vh;
}

.routes {
  &-about-btn,
  &-investments-btn,
  &-monsoon-tech-btn {
    p {
      position: relative;
      &:after {
        width: 100%;
        content: '';
        position: absolute;
        background-color: $white;
        transition: background-color 0.3s ease, bottom 0.3s ease;
        bottom: -10px;
        left: 0;
      }
      &:hover {
        &:after {
          height: 2px;
          bottom: -2px;
        }
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $familyG;
  font-weight: $medium;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b3b3b3;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #b3b3b3;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #b3b3b3;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #b3b3b3;
}

@media only screen and (min-width: $desktop) {
  section {
    > div:not(.clear-spacing-ds) {
      width: 941px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  body {
    padding-top: 0;
  }
}
