@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.monsoon-page {
  .full-height {
    min-height: 80vh;
  }
  .mobile-section-width {
    width: 295px;
    margin-right: auto;
    margin-left: auto;
  }
  &-head {
    &-wrapper {
      min-height: 100vh;
      background-repeat: no-repeat;
      background-size: 93%;
      background-position: 0% 279px;
      &-text {
        h1 {
          font-size: 54px;
          font-weight: $medium;
          letter-spacing: -2.7px;
          line-height: 70px;
          margin-top: 48px;
          margin-bottom: 0;
        }
        h2 {
          font-size: 79px;
          font-weight: $medium;
          letter-spacing: -3.95px;
          line-height: 68px;
          margin-bottom: 280px;
          margin-top: 0;
        }

        h3 {
          font-size: 36px;
          line-height: 52px;
          letter-spacing: -1.8px;
          font-weight: $medium;
          padding-bottom: 10px;
          margin-bottom: 13px;
          border-bottom: 3px solid $grey;
          background-image: linear-gradient(351deg, #00c08c 30%, #00785a 100%);
          background-size: 100%;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
          background-repeat: repeat;
        }
        p {
          font-size: 17px;
          font-weight: $medium;
          letter-spacing: -0.78px;
          line-height: 25px;
          margin-bottom: 8px;
          margin-top: 0;
        }
        h4 {
          font-size: 29px;
          font-weight: $medium;
          letter-spacing: -1.42px;
          line-height: 32px;
          margin-bottom: 110px;
          margin-top: 0;
        }
      }
    }
  }

  .in-the-press {
    padding-bottom: 65px;
    &-wrapper {
      margin-bottom: 35px;
      &-images {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > * {
          padding-bottom: 7px;
          width: 44%;
        }
      }
      &-content {
        background: linear-gradient(180deg, #eeeeee 0%, #fff 50%);
        > div {
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: space-between;
          > * {
            padding-top: 7px;
            width: 44%;
            p {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .section-1 {
    &-title {
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
      h2 {
        font-size: 33px;
        font-weight: $medium;
        letter-spacing: -1.65px;
        line-height: 31px;
        margin-bottom: 8px;
        margin-top: 0px;
      }
      h1 {
        font-size: 56px;
        font-weight: $medium;
        letter-spacing: -2.8px;
        line-height: 56px;
        margin-bottom: 62px;
        margin-top: 0px;
      }
    }
    &-content {
      &-item {
        margin-bottom: 58px;
        &-title {
          margin-top: 0;
          margin-bottom: 11px;
          padding-top: 16px;
          border-top: 3px solid $light-green;
          font-size: 33px;
          line-height: 38px;
          letter-spacing: -1.65px;
        }
        &-paragraph {
          margin-top: 0;
          margin-bottom: 11px;
          font-size: 16px;
          line-height: 23px;
          p {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    &-image {
      &-desktop {
        display: none;
      }
      &-mobile {
        width: 100%;
      }
    }
  }

  .section-2 {
    &-title {
      position: relative;
      margin-bottom: 50px;
      &:before {
        position: absolute;
        background: $grey;
        height: 3px;
        width: 35%;
        right: 0;
        content: '';
        bottom: 4px;
      }
      h1 {
        font-size: 51px;
        letter-spacing: -2.55px;
        line-height: 50px;
        margin-top: 30px;
        margin-bottom: 6px;
        font-weight: $medium;
        width: 295px;
        margin-right: auto;
        margin-left: auto;
      }
      h2 {
        color: $green;
        font-weight: $medium;
        letter-spacing: -2.11px;
        padding-bottom: 10px;
        line-height: 29px;
        font-size: 30px;
        width: 295px;
        margin-top: 15px;
        margin-bottom: 0;
        margin-right: auto;
        margin-left: auto;
        background-image: linear-gradient(351deg, #00c08c 30%, #00785a 100%);
        background-size: 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        background-repeat: repeat;
      }
    }
    &-number {
      font-size: 122px;
      font-weight: $thin;
      font-family: 'Raleway', sans-serif;
      text-align: center;
      color: $green;
      position: relative;
      margin-bottom: 27px;
      line-height: 48px;
      letter-spacing: -6.1px;
      &:before {
        position: absolute;
        background: #e3e3e3;
        height: 3px;
        width: 43%;
        left: 0;
        content: '';
        bottom: -18px;
      }
    }
    &-wrapper-one {
      &-title {
        margin-bottom: 47px;
        h1 {
          margin: 0;
          margin-bottom: 30px;
          font-size: 35px;
          line-height: 50px;
          letter-spacing: -1.75px;
        }
        .subtitle {
          margin-top: 0px;
          margin-bottom: 35px;
          letter-spacing: 7.04px;
          font: normal normal bold 12px/48px $familyG;
        }
        .desc {
          font-size: 17px;
          margin-top: 0;
          font-weight: $light;
          p {
            margin-top: 0;
          }
        }
      }
    }
    &-image {
      &-desktop {
        display: none;
      }
      &-mobile {
        width: 100%;
        margin-bottom: 50px;
      }
    }
    &-economic-growth {
      .graph-one {
        &-image {
          &-desktop {
            display: none;
          }
          &-mobile {
            width: 100%;
          }
        }
      }
      .graph-two {
        width: 290px;
        &-image {
          &-desktop {
            display: none;
          }
          &-mobile {
            width: 100%;
            margin-left: 33px;
            margin-top: 48px;
            margin-bottom: 78px;
          }
        }
      }
      .graph-three {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.04) 0%,
          rgba(255, 255, 255, 1) 28%
        );
        &-image {
          &-desktop {
            display: none;
          }
          &-mobile {
            margin-left: 33px;
            width: 290px;
            padding-top: 170px;
          }
        }
      }
    }
    &-demographic-dividend {
      &-number {
        font-size: 122px;
        font-weight: $thin;
        font-family: 'Raleway', sans-serif;
        text-align: center;
        color: $green;
        position: relative;
        margin-bottom: 27px;
        line-height: 48px;
        letter-spacing: -6.1px;
        &:before {
          position: absolute;
          background: #e3e3e3;
          height: 3px;
          width: 38%;
          right: 0;
          content: '';
          bottom: -18px;
        }
      }
      &-title {
        margin-bottom: 27px;
        h1 {
          margin: 0;
          margin-bottom: 40px;
          margin-top: 80px;
          font-size: 35px;
          line-height: 40px;
          letter-spacing: -1.75px;
          text-align: center;
        }
        .subtitle {
          margin-top: 0px;
          margin-bottom: 44px;
          letter-spacing: 7.63px;
          font: normal normal bold 12px/48px $familyG;
          text-align: center;
          line-height: 31px;
          font-size: 13px;
        }
        .desc {
          font-size: 17px;
          font-weight: $light;
          margin-bottom: 30px;
          margin-top: 0;
          p {
            margin-top: 0;
          }
        }
      }
      &-image {
        &-desktop {
          display: none;
        }
        &-mobile {
          width: 100%;
          margin-bottom: 50px;
        }
      }
      &-economic-effect {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.04) 0%,
          rgba(255, 255, 255, 1) 28%
        );
        position: relative;
        span {
          display: block;
        }
        h1 {
          font-size: 43px;
          line-height: 43px;
          letter-spacing: -2.14px;
          font-weight: $medium;
          margin-top: 0;
          margin-bottom: 16px;
        }
        h2 {
          color: $green;
          font-size: 28px;
          line-height: 34px;
          letter-spacing: -1.4px;
          font-weight: $medium;
          margin-bottom: 0;
        }
        p {
          margin-top: 0;
          font-size: 17px;
          line-height: 25px;
          margin-bottom: 70px;
        }
        &-image {
          &-desktop {
            display: none;
          }
          &-mobile {
            width: 100%;
            margin-bottom: 50px;
          }
        }
      }
      &-potent-business {
        &-image {
          &-desktop {
            display: none;
          }
          &-mobile {
            width: 295px;
            display: flex;
            margin: 0 auto;
            margin-bottom: 110px;
            margin-top: 30px;
          }
        }
      }
    }
    &-education {
      &-number {
        font-size: 122px;
        font-weight: $thin;
        font-family: 'Raleway', sans-serif;
        text-align: center;
        color: $green;
        position: relative;
        margin-bottom: 27px;
        line-height: 48px;
        letter-spacing: -6.1px;
        &:before {
          position: absolute;
          background: #e3e3e3;
          height: 3px;
          width: 40%;
          left: 0;
          content: '';
          bottom: -18px;
        }
      }
      &-title {
        margin-bottom: 27px;
        h1 {
          margin: 0 auto;
          width: 90%;
          margin-bottom: 30px;
          margin-top: 50px;
          font-size: 35px;
          line-height: 40px;
          letter-spacing: -1.75px;
          text-align: center;
        }
        .subtitle {
          margin-top: 0px;
          margin-bottom: 35px;
          letter-spacing: 7.04px;
          font: normal normal bold 12px/48px $familyG;
          text-align: center;
          line-height: 22px;
        }
        .desc {
          font-size: 17px;
          margin-top: 0;
          p {
            margin-top: 0;
          }
        }
      }

      &-image {
        &-desktop {
          display: none;
        }
        &-mobile {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    &-skilled-workforce {
      &-number {
        font-size: 122px;
        font-weight: $thin;
        font-family: 'Raleway', sans-serif;
        text-align: center;
        color: $green;
        position: relative;
        margin-bottom: 27px;
        line-height: 48px;
        letter-spacing: -6.1px;
        &:before {
          position: absolute;
          background: #e3e3e3;
          height: 3px;
          width: 40%;
          right: 0;
          content: '';
          bottom: -10px;
        }
      }

      &-image {
        &-desktop {
          display: none;
        }
        &-mobile {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      &-title {
        margin-bottom: 27px;
        h1 {
          margin: 0 auto;
          width: 90%;
          margin-bottom: 30px;
          margin-top: 50px;
          font-size: 35px;
          line-height: 40px;
          letter-spacing: -1.75px;
          text-align: center;
        }
        .subtitle {
          margin-top: 0px;
          margin-bottom: 35px;
          letter-spacing: 7.04px;
          font: normal normal bold 12px/48px $familyG;
          text-align: center;
          line-height: 22px;
        }
        .desc {
          font-size: 17px;
          margin-top: 0;
          p {
            margin-top: 0;
          }
        }
      }
      &-desc2 {
        .desc-wrapper {
          p {
            margin: 30px 0;
            &:first-child {
              font-size: 31px;
              line-height: 33px;
              font-family: $familyG;
              font-weight: $medium;
              letter-spacing: -1.55px;
            }
            font-size: 17px;
            line-height: 25px;
            font-weight: $light;
          }
        }
        h2 {
          color: $green;
          font-size: 62px;
          line-height: 58px;
          letter-spacing: -3.1px;
          margin: 0;
        }
      }
    }
    &-strategically-located {
      margin-bottom: 135px;
      &-number {
        font-size: 122px;
        font-weight: $thin;
        font-family: 'Raleway', sans-serif;
        text-align: center;
        color: $green;
        position: relative;
        margin-bottom: 27px;
        line-height: 48px;
        letter-spacing: -6.1px;
        &:before {
          position: absolute;
          background: #e3e3e3;
          height: 3px;
          width: 40%;
          left: 0;
          content: '';
          bottom: -22px;
        }
      }
      &-title {
        margin-bottom: 27px;
        h1 {
          margin: 0 auto;
          width: 90%;
          margin-bottom: 30px;
          margin-top: 50px;
          font-size: 35px;
          line-height: 40px;
          letter-spacing: -1.75px;
          text-align: center;
        }
        .subtitle {
          margin-top: 0px;
          margin-bottom: 35px;
          letter-spacing: 7.04px;
          font: normal normal bold 12px/48px $familyG;
          text-align: center;
          line-height: 22px;
        }
        .desc {
          font-size: 17px;
          margin-top: 0;
          p {
            margin-top: 0;
          }
        }
      }

      &-image {
        > img {
          width: 100%;
        }
        &-desktop {
          display: none;
        }
        &-mobile {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    &-bip {
      margin-bottom: 33px;
      &-number {
        font-size: 122px;
        font-weight: $thin;
        font-family: 'Raleway', sans-serif;
        text-align: center;
        color: $green;
        position: relative;
        margin-bottom: 27px;
        line-height: 48px;
        letter-spacing: -6.1px;
        &:before {
          position: absolute;
          background: #e3e3e3;
          height: 3px;
          width: 38%;
          right: 0;
          content: '';
          bottom: -8px;
        }
      }
      &-title {
        margin-bottom: 27px;
        h1 {
          margin: 0 auto;
          width: 90%;
          margin-bottom: 30px;
          margin-top: 50px;
          font-size: 35px;
          line-height: 40px;
          letter-spacing: -1.75px;
          text-align: center;
        }
        .subtitle {
          margin-top: 0px;
          margin-bottom: 35px;
          letter-spacing: 7.04px;
          font: normal normal bold 12px/48px $familyG;
          text-align: center;
          line-height: 22px;
        }
        .desc {
          font-size: 17px;
          margin-top: 0;
          p {
            margin-top: 0;
          }
        }
      }

      &-desc2 {
        .desc-wrapper {
          margin-top: 0;

          p {
            margin-top: 0;
            font-size: 17px;
            line-height: 25px;
            font-weight: $light;
          }
        }
      }

      &-image {
        &-desktop {
          display: none;
        }
        &-mobile {
          width: 100%;
          margin-bottom: 10px;
        }
      }

      &-number-image {
        display: flex;
        margin-top: 30px;
        &-desktop {
          display: none;
        }
        &-mobile {
          width: 90%;
          margin-bottom: 10px;
          margin-left: auto;
        }
      }
      &-three-sections {
        margin-top: 50px;
        margin-bottom: 170px;
        &-item {
          margin-bottom: 60px;
          &-title {
            display: flex;
            align-items: center;
            width: 70px;
            img {
              width: 70px;
            }
            h3 {
              margin: 0;
              margin-left: 14px;
              padding-top: 9px;
              border-top: 1px solid $grey;
              font-size: 22px;
              line-height: 25px;
              font-weight: $medium;
              letter-spacing: -0.45px;
            }
            p {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  .section-3 {
    &-title {
      text-align: center;
      position: relative;
      h2 {
        margin-bottom: 0;
        font-size: 37px;
        line-height: 37px;
        letter-spacing: -1.85px;
        font-weight: $medium;
      }
      h1 {
        font-size: 50px;
        background-image: linear-gradient(0deg, #00c08c 13%, #00785a 100%);
        line-height: 50px;
        font-weight: $bold;
        margin-top: 0;
        background-size: 100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        background-repeat: repeat;
      }
      &:before {
        position: absolute;
        background: #e3e3e3;
        height: 3px;
        width: 30%;
        left: 0;
        content: '';
        bottom: 2px;
      }
      &:after {
        position: absolute;
        background: #e3e3e3;
        height: 3px;
        width: 30%;
        right: 0;
        content: '';
        bottom: 2px;
      }
    }
    &-content {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.04) 0%,
        rgba(255, 255, 255, 1) 28%
      );
      padding-bottom: 90px;
      &-wrapper {
        margin-bottom: 47px;
        .number {
          text-align: center;
          font-family: $familyG;
          font-size: 20px;
          font-weight: $regular;
          line-height: 11px;
          letter-spacing: 3.16px;
          color: $grey;
          padding-bottom: 11px;
          border-bottom: 2px solid $light-green;
        }
        span {
          text-align: center;
          h3 {
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -1.58px;
            font-weight: $medium;
          }
          p {
            font-size: 17px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .section-4 {
    &-title {
      h1 {
        font-size: 78px;
        line-height: 60px;
        letter-spacing: -4.84px;
        font-weight: $medium;
        text-align: center;
      }
    }
    &-content {
      padding-bottom: 102px;
      &-wrapper {
        margin-bottom: 47px;
        text-align: center;
        .number {
          text-align: center;
          font-family: $familyG;
          font-size: 20px;
          font-weight: $regular;
          line-height: 11px;
          letter-spacing: 3.16px;
          color: $grey;
          margin-bottom: 20px;
        }
        h1 {
          padding-bottom: 11px;
          margin-top: 0;
          border-bottom: 2px solid $light-green;
          font-weight: $medium;
          font-size: 26px;
          line-height: 29px;
          letter-spacing: -1.3px;
          margin-bottom: 0;
          padding-bottom: 10px;
        }
        span {
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .latest-news-section {
    width: 295px;
    margin-right: auto;
    margin-left: auto;
    &-heading {
      h1 {
        font-size: 34px;
        line-height: 46px;
        letter-spacing: -1.7px;
        margin: 0;
        border-bottom: 3px solid $orange;
      }
      h2 {
        font-size: 47px;
        line-height: 45px;
        letter-spacing: -2.91px;
        margin: 0;
      }
      margin: 60px 0 18px;
      font-weight: $medium;
      font-size: 39px;
      line-height: 46px;
      text-align: center;
      padding-bottom: 8px;
    }
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 28px;
    }
    &-wrapper {
      display: flex;
      margin-bottom: 17px;
      text-decoration: none;
      color: $black;
      border-bottom: 1.5px solid $grey;
      padding-bottom: 19px;
    }
    img {
      width: 30%;
      margin-right: 13px;
    }
    &-content {
      width: 82%;
      text-decoration: none;
      &-date {
        font-size: 11px;
        margin: 0;
        margin-bottom: 5px;
        line-height: 16px;
      }
      &-desc {
        font-size: 17px;
        line-height: 19px;
        margin: 0;
      }
    }
    .load-more-content {
      text-align: center;
      position: relative;
      &-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: transparent
          linear-gradient(180deg, #ffffff00 0%, #ffffff7b 39%, #ffffff 100%) 0%
          0% no-repeat padding-box;
        top: -95px;
        z-index: 1;
      }
      span {
        border-bottom: 2px solid $black;
        padding-bottom: 10px;
        display: inline-block;
        margin-bottom: 100px;
      }
    }
  }

  .team-section {
    background: linear-gradient(
      203deg,
      rgba(0, 0, 0, 0.04) 0%,
      rgba(255, 255, 255, 1) 34%
    );
    border-bottom: 6px solid $green;
    &-wrapper {
      width: unset !important ;
      margin: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      text-align: center;
      padding-bottom: 223px;
      padding-top: 50px;

      span {
        display: block;
        width: 295px;
        margin-right: auto;
        margin-left: auto;
        h1 {
          font-size: 70px;
          line-height: 51px;
          margin: 0;
          font-weight: $medium;
        }
        h2 {
          margin: 0;
          margin-bottom: 50px;
          font-size: 26px;
          line-height: 45px;
          font-weight: $medium;
        }
      }
      a {
        font-size: 22px;
        color: $green;
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding-bottom: 60px;
        text-decoration: none;
        p {
          margin: 0;
          position: relative;
          margin-right: 15px;
          font-size: 22px;
          padding: 0 10px;
          &:after {
            width: 100%;
            content: '';
            position: absolute;
            height: 2px;
            background-color: $grey;
            transition: background-color 0.3s ease, bottom 0.3s ease;
            bottom: -10px;
            left: 0;
          }
        }
        &:hover {
          p {
            &:after {
              background-color: $green;
              bottom: -2px;
            }
          }
        }
        img {
          width: 5px;
          height: 14px;
        }
      }
    }
  }

  + footer {
    display: none;
  }
}

@media only screen and (min-width: $desktop) {
  .monsoon-page {
    .mobile-section-width,
    .desktop-section-width {
      width: 940px;
      margin-right: auto;
      margin-left: auto;
    }
    &-head {
      &-wrapper {
        background-size: 61%;
        background-position: 123% 82px;
        padding-bottom: 10px;
        &-text {
          h1 {
            font-size: 171px;
            letter-spacing: -8.55px;
            line-height: 149px;
            margin-top: 80px;
            margin-bottom: 0;
          }
          h2 {
            font-size: 59px;
            line-height: 59px;
            letter-spacing: -2.89px;
            width: 30%;
            margin-bottom: 0;
            padding-bottom: 38.59px;
          }
          h3 {
            border-bottom: none;
            border-top: 3px solid $grey;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 45px;
            padding-bottom: 0;
            width: 49.2%;
          }
          p {
            width: 40%;
            font-size: 20px;
            line-height: 31px;
            letter-spacing: -0.92px;
            margin-bottom: 40px;
            font-weight: $medium;
          }
          h4 {
            width: 41%;
            font-size: 39px;
            line-height: 43px;
            margin-bottom: 155px;
            letter-spacing: -1.91px;
          }
        }
      }
    }

    .in-the-press {
      padding-bottom: 127px;
      &-logos-wrapper,
      &-content-wrapper {
        display: flex;
        padding: 0 25%;
        justify-content: space-between;
        align-items: flex-end;
      }
      &-logos,
      &-content {
        width: 18%;
        text-align: center;
        p {
          padding-top: 15px;
          margin-top: 0;
          font-size: 20px;
          letter-spacing: -1px;
          line-height: 23px;
        }
        img {
          width: 100%;
        }
      }
      &-logos {
        margin-bottom: 15px;
      }
      &-content-wrapper {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.04) 0%,
          rgba(255, 255, 255, 1) 34%
        );
        align-items: flex-start;
      }
    }
    .section-1 {
      &-title {
        h2 {
          font-size: 49px;
          line-height: 51px;
          letter-spacing: -2.45px;
        }
        h1 {
          font-size: 132px;
          line-height: 122px;
          letter-spacing: -6.6px;
        }
      }
      &-content {
        display: flex;
        justify-content: space-between;
        &-item {
          width: 30%;
          &-title {
            font-size: 33px;
            padding-top: 22px;
            line-height: 38px;
            letter-spacing: -1.65px;
          }
          &-paragraph {
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
      &-image {
        &-desktop {
          display: block;
          width: 83%;
        }
        &-mobile {
          display: none;
        }
      }
    }
    .section-2 {
      &-title {
        h1 {
          font-size: 113px;
          line-height: 99px;
          margin-top: 61px;
          letter-spacing: -6.44px;
          width: 940px;
        }
        h2 {
          width: 390px;
          margin-top: 25px;
          font-size: 40px;
          line-height: 38px;
        }
        &:before {
          position: absolute;
          background: #e3e3e3;
          height: 3px;
          width: 45%;
          right: 0;
          content: '';
          bottom: 10px;
        }
      }
      &-number {
        margin-bottom: 40px;
        span {
          display: block;
          width: 940px;
          text-align: left;
          margin-left: auto;
          margin-right: auto;
        }
        &:before {
          position: absolute;
          background: #e3e3e3;
          height: 3px;
          width: 18%;
          left: 0;
          content: '';
          bottom: -18px;
        }
      }
      &-wrapper {
        margin-top: 30px;
        &-one-title {
          h1 {
            font-size: 51px;
            line-height: 50px;
            letter-spacing: -2.55px;
          }
          .desc {
            width: 40%;
            line-height: 25px;
          }
          .subtitle {
            letter-spacing: 7.63px;
            font-size: 13px;
            line-height: 48px;
            margin-bottom: 25px;
          }
        }
      }
      &-image {
        &-desktop {
          display: block;
          width: 100%;
        }
        &-mobile {
          display: none;
        }
      }
      &-economic-growth {
        .graph-one {
          &-image {
            &-desktop {
              display: block;
              width: 100%;
              margin-top: 132px;
              margin-bottom: 20px;
            }
            &-mobile {
              display: none;
            }
          }
        }
        .graph-two {
          width: 940px;
          margin: 0 auto;
          margin-bottom: 115px;
          &-image {
            &-desktop {
              display: block;
              width: 100%;
            }
            &-mobile {
              display: none;
            }
          }
        }
        .graph-three {
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.04) 0%,
            rgba(255, 255, 255, 1) 28%
          );
          &-image {
            &-desktop {
              display: block;
              margin: 0 auto;
              width: 940px;
              padding-top: 122px;
              margin-bottom: 20px;
            }
            &-mobile {
              display: none;
            }
          }
        }
      }
      &-demographic-dividend {
        &-number {
          span {
            display: block;
            width: 534px;
            margin: 0 auto;
            text-align: left;
          }
          &:before {
            position: absolute;
            background: #e3e3e3;
            height: 3px;
            width: 62%;
            right: 0;
            content: '';
            bottom: -18px;
          }
        }
        &-image {
          &-desktop {
            display: block;
            width: 100%;
            padding-bottom: 160px;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.04) 0%,
              rgba(255, 255, 255, 1) 34%
            );
          }
          &-mobile {
            display: none;
          }
        }
        &-title {
          span {
            display: block;
            width: 534px;
            margin: 0 auto;

            h1 {
              text-align: left;
              font-size: 51px;
              line-height: 50px;
              letter-spacing: -2.55px;
            }

            .subtitle {
              font-size: 13px;
              letter-spacing: 7.63px;
              line-height: 31px;
              text-align: left;
            }
          }
        }
        &-economic-effect {
          &-wrapper {
            h2 {
              text-align: center;
              font-size: 38px;
              line-height: 34px;
              letter-spacing: -1.9px;
              margin-bottom: 10px;
            }
            h1 {
              text-align: center;
              font-size: 56px;
              line-height: 34px;
              margin-bottom: 30px;
              letter-spacing: -2.8px;
            }
            p {
              text-align: center;
              p {
                width: 62%;
                font-size: 17px;
                margin: 0 auto;
                margin-top: 30px;
              }
            }
          }
          &-image {
            &-desktop {
              display: block;
              padding-bottom: 100px;
              width: 100%;
            }
            &-mobile {
              display: none;
            }
          }
        }
        &-potent-business {
          &-image {
            &-desktop {
              display: block;
              width: 630px;
              margin: 0 auto;
              margin-top: 40px;
              margin-bottom: 100px;
            }
            &-mobile {
              display: none;
            }
          }
        }
      }
      &-education {
        background-repeat: no-repeat;
        background-size: 40% 30%;
        background-position: 100% 29%;
        &-number {
          span {
            display: block;
            width: 940px;
            text-align: left;
            margin-left: auto;
            margin-right: auto;
          }
          &:before {
            position: absolute;
            background: #e3e3e3;
            height: 3px;
            width: 18%;
            left: 0;
            content: '';
            bottom: -18px;
          }
        }
        &-title {
          > p {
            width: 45%;
          }
          h1 {
            margin-right: 0;
            margin-left: 0;
            margin-top: 70px;
            font-size: 51px;
            letter-spacing: -2.55px;
            line-height: 50px;
            text-align: left;
          }
          .subtitle {
            text-align: left;
            font-size: 13px;
            letter-spacing: 7.63px;
            line-height: 48px;
            margin-bottom: 40px;
          }
          .desc {
            width: 46%;
            p {
              font-weight: $light;
              font-size: 17px;
              line-height: 25px;
              margin-bottom: 20px;
            }
            h2 {
              font-size: 33px;
              letter-spacing: -1.65px;
              line-height: 36px;
            }
          }
        }
        &-image {
          &-desktop {
            display: block;
            width: 940px;
            margin: 0 auto;
            position: relative;
            left: -25px;
          }
          &-mobile {
            display: none;
          }
        }
      }
      &-skilled-workforce {
        margin-bottom: 100px;
        &-number {
          span {
            display: block;
            width: 534px;
            margin: 0 auto;
            text-align: left;
          }
          &:before {
            position: absolute;
            background: #e3e3e3;
            height: 3px;
            width: 62%;
            right: 0;
            content: '';
            bottom: -18px;
          }
        }
        &-image {
          &-desktop {
            display: block;
            width: 100%;
          }
          &-mobile {
            display: none;
          }
        }

        &-title {
          span {
            display: block;
            width: 534px;
            margin: 0 auto;

            h1 {
              text-align: left;
              font-size: 51px;
              line-height: 50px;
              letter-spacing: -2.55px;
              margin-right: 0;
              margin-left: 0;
            }
          }
          .subtitle {
            font-size: 13px;
            letter-spacing: 7.63px;
            line-height: 31px;
            text-align: left;
            width: 534px;
            margin: 0 auto;
            margin-bottom: 47px;
            text-align: left;
          }
          .desc {
            width: 534px;
            margin: 0 auto;
            text-align: left;
            font-weight: $light;
          }
        }
        span {
          display: flex;
          img {
            width: 30%;
            height: 262px;
          }
        }
        &-desc2 {
          margin-left: 37px;
          .desc-wrapper {
            width: 534px;
            text-align: left;
            margin: 0;
            h2 {
              font-size: 57px;
            }
            p {
              &:not(:last-child) {
                margin-top: 0;
                width: 80%;
                margin-bottom: 20px;
              }
            }
            &-content {
              display: flex;
              justify-content: space-between;
              &-item {
                width: 30%;
                &-title {
                  font-size: 33px;
                  padding-top: 22px;
                  line-height: 38px;
                  letter-spacing: -1.65px;
                }
                &-paragraph {
                  font-size: 16px;
                  line-height: 23px;
                }
                &:not(:first-child) {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
      &-strategically-located {
        padding-top: 180px;
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: right 54px;
        &-number {
          span {
            display: block;
            width: 940px;
            text-align: left;
            margin-left: auto;
            margin-right: auto;
          }
          &:before {
            position: absolute;
            background: #e3e3e3;
            height: 3px;
            width: 18%;
            left: 0;
            content: '';
            bottom: -18px;
          }
        }

        &-title {
          h1 {
            margin-right: 0;
            margin-left: 0;
            text-align: left;
            font-size: 51px;
            letter-spacing: -2.55px;
            line-height: 50px;
            margin-bottom: 40px;
            width: 35%;
          }
          .subtitle {
            text-align: left;
            font-size: 13px;
            letter-spacing: 7.63px;
            line-height: 25px;
          }
          .desc {
            p {
              width: 40%;
              font-weight: $light;
            }
          }
        }
        &-image-mobile {
          display: none;
        }
        &-image {
          img {
            width: 170px;
          }
        }
        .desc-wrapper {
          p {
            width: 40%;
            font-weight: $light;
          }
          h2 {
            color: $green;
            width: 64%;
            font-size: 33px;
            line-height: 35px;
            letter-spacing: -1.88px;
            &:last-child {
              width: 45%;
            }
          }
        }
      }
      &-bip {
        background-repeat: no-repeat;
        background-position: center 266px;
        background-size: 1520px;
        margin-bottom: 180px;
        &-number {
          span {
            display: block;
            width: 534px;
            margin: 0 auto;
            text-align: center;
          }
          &:before {
            position: absolute;
            background: #e3e3e3;
            height: 3px;
            width: 46%;
            right: 0;
            content: '';
            bottom: -8px;
          }
        }
        &-image {
          &-desktop {
            display: block;
            width: 100%;
          }
          &-mobile {
            display: none;
          }
        }
        &-title {
          margin-bottom: 150px;
          span {
            display: flex;
            justify-content: flex-end;
          }
          h1 {
            width: 52%;
            text-align: left;
            font-size: 47px;
            line-height: 48px;
            letter-spacing: -2.35px;
            margin-right: 0;
            margin-left: 0;
          }
          .subtitle {
            width: 91.7%;
            text-align: right;
            letter-spacing: 7.63px;
            font-size: 13px;
            line-height: 25px;
            margin-bottom: 56px;
          }
          .desc {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            p {
              width: 52%;
            }
          }
        }
        .desc-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: 50px;
          p {
            width: 52%;
          }
        }
        &-number-image {
          width: 1277px;
          height: 1220px;
          margin-bottom: 70px;
          margin-left: auto;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          &-desc-desktop {
            position: absolute;
            bottom: -20px;
            left: 45px;
            width: 40%;
            > p {
              margin-top: 0;
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
        &-three-sections {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          &-item {
            width: 30%;
            &-title {
              display: flex;
              align-items: center;

              position: relative;
              left: -18px;
              h3 {
                margin: 0;
                margin-left: 14px;
                padding-top: 9px;
                border-top: 1px solid $grey;
                font-size: 22px;
                line-height: 25px;
                font-weight: $medium;
                letter-spacing: -0.45px;
              }
              p {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
          &-item-full {
            display: flex;
            align-items: center;
            &-image {
              width: 35%;
              img {
                width: 307px;
                height: 213px;
              }
            }
            &-desc {
              width: 60%;
              h3 {
                margin: 0;
                padding-top: 9px;
                border-top: 1px solid $grey;
                letter-spacing: -0.45px;
                font-size: 32px;
                line-height: 32px;
                font-weight: $medium;
              }

              p {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
    .section-3 {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.04) 0%, white 28%);
      &-title {
        &:before,
        &:after {
          width: 22%;
          bottom: 14px;
        }
        h2 {
          font-size: 65px;
        }

        h1 {
          font-size: 100px;
          line-height: 110px;
          margin-top: 0;
          margin-bottom: 60px;
          font-weight: $bold;
        }
      }

      &-content {
        display: flex;
        background: none;
        padding-bottom: 50px;
        justify-content: space-between;
        &-wrapper {
          width: 30%;
          .number {
            padding-bottom: 18px;
          }
          span {
            h3 {
              margin-top: 18px;
              margin-bottom: 14px;
            }
            p {
              margin-top: 0;
            }
          }
          .number,
          span {
            text-align: left;
          }
        }
      }
    }
    .section-4 {
      &-title {
        h1 {
          font-size: 103px;
          line-height: 109px;
          margin-bottom: 40px;
        }
      }
      &-content {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 800px;
        &-wrapper {
          width: 42%;
          h1 {
            padding-bottom: 14px;
          }
        }
      }
    }
    .team-section {
      height: 630px;
      &-wrapper {
        width: 941px !important;
        padding: 0;
        height: 100%;
        margin: 0 auto;
        span {
          display: unset;
          width: unset;
          margin-right: unset;
          margin-left: unset;
          h1 {
            font-size: 89px;
            line-height: 68px;
            margin: 0;
            margin-bottom: 10px;
            padding-top: 110px;
            font-weight: $medium;
          }
          h2 {
            font-size: 46px;
            line-height: 45px;
            margin: 0;
            font-weight: $medium;
          }
        }
        a {
          font-size: 22px;
          margin-top: 46px;
          justify-content: center;
          p {
            margin-right: 25px;
            margin-bottom: 0;
            margin-top: 0;
          }
          img {
            width: 5px;
            height: 14px;
          }
        }
      }
    }
    .latest-news-section {
      width: 941px;
      margin-right: auto;
      margin-left: auto;
      padding-bottom: 133px;
      &-heading {
        h1 {
          font-size: 82px;
          line-height: 90px;
          letter-spacing: -4.1px;
          padding-bottom: 15px;
          margin: 0;
          border-bottom: 3px solid $green;
        }
        h2 {
          font-size: 42px;
          line-height: 32px;
          letter-spacing: -2.1px;
          margin: 0;
        }
        margin: 60px 0 18px;
        font-weight: $medium;
        font-size: 39px;
        line-height: 46px;
        text-align: center;
        border-bottom: 3px solid $orange;
        padding-bottom: 17px;
      }
      &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &-wrapper {
        width: 45%;
        border-bottom: none;
        display: flex;
        margin-bottom: 17px;
        text-decoration: none;
        color: $black;
      }
      img {
        width: 18%;
        margin-right: 13px;
      }
      &-content {
        border-bottom: 1.5px solid $grey;
        width: 82%;
        padding-bottom: 10px;
        text-decoration: none;
        &-date {
          font-size: 11px;
          margin: 0;
          margin-bottom: 5px;
          line-height: 16px;
        }
        &-desc {
          font-size: 17px;
          line-height: 19px;
          font-weight: $medium;
          margin: 0;
        }
      }
      .load-more-content {
        text-align: center;
        position: relative;
        &-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          background: transparent
            linear-gradient(180deg, #ffffff00 0%, #ffffff7b 39%, #ffffff 100%)
            0% 0% no-repeat padding-box;
          top: -95px;
          z-index: 1;
        }
        span {
          border-bottom: 2px solid $black;
          padding-bottom: 10px;
          display: inline-block;
          margin-bottom: 100px;
        }
      }
    }
  }
}

@media only screen and (min-width: $largeDesk) {
  .monsoon-page {
    &-head {
      &-wrapper {
        padding-bottom: 150px;
        background-size: 57%;
        background-position: 113% 80px;
      }
    }
  }
}
