.desktop-header {
  display: none;
}
.mobile-header {
  border-bottom: 1px solid $light-grey;
  position: fixed;
  z-index: 1000;
  width: 100%;
  background: white;
  top: 0;
  &-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 24px 29px 18px;
    align-items: center;
  }
}
.burger-menu {
  font-family: $familyG;
  .bm-overlay {
    z-index: -100 !important;
    opacity: 0 !important;
  }
  .bm-links {
    display: flex !important;
    flex-direction: column;
    margin-top: 120px;
    text-align: center;
    a {
      text-decoration: none;
      color: $white;
      font-size: 39px;
      letter-spacing: -1.68px;
      line-height: 51px;
      font-weight: $medium;
    }
  }
  .linked-in-icon {
    margin-top: 50px;
    margin-bottom: 65px;
    text-align: center;
  }
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    width: 30px;
    height: 30px;
    right: 0;
    button {
      outline: unset !important;
    }
  }

  .bm-trademark {
    font-size: 13px;
    line-height: 55px;
    letter-spacing: 0.68px;
    font-family: $familyG;
    font-weight: $light;
    position: absolute;
    bottom: 40px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $black;
    background: #000;
    height: 4px !important;
    border-radius: 15%;
    width: 40px;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #eca885;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    right: 30px !important;
    top: 30px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: $white;
    width: 1px !important;
    height: 31px !important;
  }

  /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
    width: 100% !important;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #005744;
    opacity: 0.95;
    padding: 1.5em 1.5em 0;
    overflow: hidden !important;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    position: relative;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}
@media only screen and (min-width: $desktop) {
  .mobile-header {
    display: none;
  }
  .desktop-header {
    border-bottom: 2px solid $light-grey;
    display: block;
    &-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 1300px;
      margin-right: auto;
      margin-left: auto;
      padding: 25px;
      align-items: center;
      > a {
        z-index: 1100;
        width: 105px;
        height: 51px;
        img {
          position: fixed;
        }
      }
    }
    .burger-menu {
      width: 106px;
      height: 30px;
      z-index: 1100;
      > div {
        position: fixed;
        margin-left: 67px;
      }
      .white-logo {
        display: none !important;
      }
      .bm-menu-wrap {
        width: 401px !important;
      }
      .bm-menu {
        border-top-left-radius: 17px;
        border-bottom-left-radius: 17px;
        padding: 2.5em 1.5em 0;
      }
      .bm-links {
        text-align: left;
      }

      .linked-in-icon {
        text-align: left;
      }
      > div {
        .bm-overlay + div {
          position: relative;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .nav-bar {
      display: flex;
      justify-content: space-between;
      width: 500px;
      font-size: 13px;
      .active {
        border-bottom: 2px solid $orange;
      }
      a {
        text-decoration: none;
        font-family: $familyB;
        font-weight: $bold;
        color: $black;
        letter-spacing: 1.95px;
        line-height: 17.86px;
        position: relative;
        &:after {
          width: 100%;
          content: '';
          position: absolute;
          background-color: $orange;
          transition: background-color 0.3s ease, bottom 0.3s ease;
          bottom: -10px;
          left: 0;
        }
        &:hover {
          &:after {
            height: 2px;
            bottom: -2px;
          }
        }
        &.active {
          &:after {
            display: none;
          }
        }
      }
    }
    .menu-icon {
      padding-left: 73px;
    }
  }
}
