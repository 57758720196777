.jobs-page {
  min-height: 100vh;
  &-title {
    text-align: center;
    margin: 58px 0;
    h1 {
      font-size: 49px;
      line-height: 43px;
      letter-spacing: -3.19px;
      font-weight: $medium;
      margin: 0;
    }
    h2 {
      font-size: 35px;
      font-weight: $medium;
      letter-spacing: -2.27px;
      line-height: 30px;
      margin: 0;
    }
  }
  .company-section {
    display: flex;
    flex-wrap: wrap;
    &-item {
      margin: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
      }
    }
    &-desc {
      display: none;
    }
  }
  .routes {
    background-color: $darkGreen;
    width: unset;
    &-about-btn {
      font-size: 35px;
      padding: 38px 0;
      max-width: 295px;
      border-bottom: solid black 1px;
      margin: 0 auto;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-decoration: none;
      p {
        margin: 0;
        position: relative;
        margin-right: 25px;
      }

      img {
        width: 8px;
        height: 22px;
      }
    }
    &-monsoon-tech-btn {
      margin: 0;
      font-size: 35px;
      padding: 38px 0;
      max-width: 295px;
      margin: 0 auto;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-decoration: none;
      p {
        margin: 0;
        position: relative;
        margin-right: 25px;
      }

      img {
        width: 8px;
        height: 22px;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .jobs-page {
    &-title {
      text-align: center;
      margin: 55px 0 78px;
      h1 {
        font-size: 81px;
        line-height: 66px;
        letter-spacing: -3.48px;
        font-weight: $medium;
        margin: 0;
      }
      h2 {
        font-size: 46px;
        font-weight: $medium;
        letter-spacing: -1.98px;
        line-height: 66px;
        margin: 0;
      }
    }
    .company-section {
      display: flex;
      &-item {
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        img {
          width: 90%;
        }
        &:hover {
          .company-section-desc {
            opacity: 1;
            &-text {
              transform: scale(1);
            }
          }
        }
      }
      &-desc {
        opacity: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: opacity 0.3s ease-in;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: white;
        img {
          width: 45%;
          padding-top: 13px;
        }
        &-text {
          text-align: center;
          width: 60%;
          transition: transform 0.5s ease-in-out;
          transform: scale(1.2);
        }
      }
    }
    .routes {
      > div {
        width: 1200px;
        display: flex;
        justify-content: space-between;
      }
      &-about-btn {
        margin: 0;
        font-size: 57px;
        padding: 50px 0;
        img {
          width: 11px;
          height: 31px;
        }
      }
      &-monsoon-tech-btn {
        font-size: 57px;
        margin: 0;
        padding: 50px 0;
        max-width: unset;
        img {
          width: 11px;
          height: 31px;
        }
      }
    }
  }
}
