.about-page {
  min-height: 100vh;
  .head-section {
    &-wrapper {
      text-align: center;
      border-bottom: 3px solid $orange;
      h1 {
        font-size: 43px;
        line-height: 42px;
        margin-top: 75px;
        margin-bottom: 20px;
        font-weight: $medium;
      }
      .top {
        font-size: 19px;
        line-height: 25px;
        margin-bottom: 18px;
      }
      .bottom {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 54px;
      }
    }
  }
  .razor-rule-section {
    &-wrapper {
      h1 {
        margin-top: 75px;
        margin-bottom: 37px;
        text-align: center;
        h1 {
          margin: 0;
          font-size: 44px;
          line-height: 40px;
          font-weight: $medium;
        }
        h2 {
          margin: 0;
          font-size: 23px;
          line-height: 21px;
          font-weight: $medium;
        }
      }
      > p {
        font-size: 19px;
        line-height: 27px;
        text-align: center;
        margin: 0;
        margin-bottom: 72px;
        p {
          margin: 0;
        }
      }
      .three-parg {
        .number-top {
          margin: 0;
          text-align: center;
          font-size: 27px;
          color: $light-grey;
          margin-bottom: 12px;
        }
        h2 {
          margin: 0;
          font-size: 27px;
          color: $green;
          border-bottom: 3px solid $grey;
          text-align: center;
          padding: 0px 10px 10px;
          margin-bottom: 30px;
        }
        &-wrapper {
          &-desc {
            > span {
              margin-bottom: 40px;
              display: block;
              h3 {
                font-weight: $medium !important;
                font-size: 20px;
                line-height: 34px;
                margin: 0;
              }
              p {
                margin: 0;
                font-size: 16px;
                line-height: 22px;
                font-weight: 100;
              }
            }
            .number {
              font-size: 20px;
              line-height: 11px;
              color: $light-grey;
              margin-bottom: 7px;
            }
          }
        }
      }
    }
  }
  .industry-section {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.04) 0%,
      rgba(255, 255, 255, 1) 28%
    );
    &-wrapper {
      padding-top: 50px;
      &-top {
        > p {
          margin-top: 50px;
          font-size: 19px;
          line-height: 27px;
          text-align: left;
          font-weight: 100;
          p {
            margin: 0;
          }
        }
      }

      .three-parg {
        .number-top {
          margin: 0;
          text-align: center;
          font-size: 27px;
          color: $light-grey;
          margin-bottom: 12px;
          font-family: 'Gordita';
        }
        h2 {
          margin: 0;
          font-size: 27px;
          color: $green;
          border-bottom: 3px solid $grey;
          text-align: center;
          padding: 0px 10px 10px;
          margin-bottom: 30px;
        }
        &-wrapper {
          &-desc {
            > span {
              margin-bottom: 40px;
              display: block;
              h3 {
                font-weight: $medium;
                font-size: 20px;
                line-height: 34px;
                margin: 0;
                letter-spacing: -1.32px;
              }
              p {
                margin: 0;
                font-weight: 100;
              }
            }
            .number {
              font-size: 20px;
              line-height: 11px;
              color: $light-grey;
              margin-bottom: 7px;
            }
          }
        }
      }
    }
  }
  .frontier-markets-section {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.04) 0%,
      rgba(255, 255, 255, 1) 28%
    );
    padding-top: 86px;
    > section {
      background-repeat: no-repeat;
      background-position: 22% 100%;
    }
    &-wrapper {
      span {
        text-align: center;
        h2 {
          font-size: 52px;
          line-height: 44px;
          letter-spacing: -3.44px;
          font-weight: $medium;
          margin: 0;
        }
        h1 {
          font-size: 41px;
          line-height: 43px;
          font-weight: $medium;
          margin: 0;
          margin-bottom: 26px;
          letter-spacing: -2.73px;
        }
        p {
          margin: 0;
          font-size: 19px;
          line-height: 27px;
          font-weight: 100;
        }
      }
      a {
        margin-top: 43px;
        font-size: 22px;
        min-height: 40px;
        color: $green;
        display: flex;
        align-items: baseline;
        padding-bottom: 150px;
        text-decoration: none;
        p {
          margin: 0;
          font-family: $familyG;
          font-weight: $medium;
          position: relative;
          margin-right: 15px;
          font-size: 22px;
          &:after {
            width: 100%;
            content: '';
            position: absolute;
            height: 2px;
            background-color: $grey;
            transition: background-color 0.3s ease, bottom 0.3s ease;
            bottom: -10px;
            left: 0;
          }
        }
        &:hover {
          p {
            &:after {
              background-color: $green;
              bottom: -2px;
            }
          }
        }
        img {
          width: 5px;
          height: 14px;
        }
      }
    }
  }
  .serve-section {
    &-wrapper {
      padding-top: 65px;
      padding-bottom: 110px;
      span {
        h1 {
          font-size: 64px;
          line-height: 44px;
          font-weight: $medium;
          text-align: center;
          margin: 0;
        }
      }
      &-desc {
        margin-bottom: 20px;
        > p {
          font-size: 21px;
          font-family: 'Gordita';
          font-weight: 500;
          line-height: 11px;
          color: $grey;
          border-bottom: 3px solid $orange;
          padding-bottom: 12px;
        }
        span {
          h3 {
            font-size: 24px;
            line-height: 26px;
            font-weight: $medium;
            margin: 0;
            margin-bottom: 9px;
            letter-spacing: -1.8px;
          }
          p {
            font-size: 16px;
            margin: 0;
            line-height: 22px;
            font-weight: 100;
          }
        }
      }
    }
  }
  .team-section {
    background: linear-gradient(
      203deg,
      rgba(0, 0, 0, 0.04) 0%,
      rgba(255, 255, 255, 1) 34%
    );
    border-bottom: 6px solid $green;
    &-wrapper {
      width: unset !important ;
      margin: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      text-align: center;
      padding-bottom: 223px;
      padding-top: 50px;

      span {
        display: block;
        width: 295px;
        margin-right: auto;
        margin-left: auto;
        h1 {
          font-size: 70px;
          line-height: 51px;
          margin: 0;
          font-weight: $medium;
        }
        h2 {
          margin: 0;
          margin-bottom: 50px;
          font-size: 26px;
          line-height: 45px;
          font-weight: $medium;
        }
      }
      a {
        font-size: 22px;
        color: $green;
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding-bottom: 60px;
        text-decoration: none;
        p {
          margin: 0;
          position: relative;
          font-family: $familyG;
          font-weight: $medium;
          margin-right: 15px;
          font-size: 22px;
          &:after {
            width: 100%;
            content: '';
            position: absolute;
            height: 2px;
            background-color: $grey;
            transition: background-color 0.3s ease, bottom 0.3s ease;
            bottom: -10px;
            left: 0;
          }
        }
        &:hover {
          p {
            &:after {
              background-color: $green;
              bottom: -2px;
            }
          }
        }
        img {
          width: 5px;
          height: 14px;
        }
      }
    }
  }
  .routes {
    background-color: $darkGreen;
    width: unset;
    &-investments-btn {
      font-size: 35px;
      padding: 38px 0;
      max-width: 295px;
      border-bottom: solid black 1px;
      margin: 0 auto;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-decoration: none;
      p {
        margin: 0;
        position: relative;
        margin-right: 25px;
      }

      img {
        width: 8px;
        height: 22px;
      }
    }
    &-monsoon-tech-btn {
      margin: 0;
      font-size: 35px;
      padding: 38px 0;
      max-width: 295px;
      margin: 0 auto;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-decoration: none;
      p {
        margin: 0;
        position: relative;
        margin-right: 25px;
      }

      img {
        width: 8px;
        height: 22px;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .about-page {
    .head-section {
      &-wrapper {
        h1 {
          font-size: 79px;
          letter-spacing: -5.13px;
          line-height: 79px;
          margin-bottom: 25px;
          margin-top: 80px;
        }
        .top {
          font-size: 26px;
          line-height: 33px;
          margin-bottom: 21px;
          width: 640px;
          margin: 0 auto;
        }
        .bottom {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 77px;
          width: 500px;
          margin: 0 auto;
          padding-bottom: 58px;
        }
      }
    }
    .razor-rule-section {
      &-wrapper {
        > h1 {
          margin-top: 119px;
          margin-bottom: 26px;
          h1 {
            font-size: 83px;
            line-height: 84px;
            letter-spacing: -4.15px;
          }
          h2 {
            font-size: 58px;
            line-height: 57px;
            letter-spacing: -2.9px;
          }
        }
        > p {
          font-size: 19px;
          line-height: 27px;
          width: 65%;
          margin: 0 auto;
          margin-bottom: 60px;
          font-weight: 100;
        }
        .three-parg {
          h2 {
            font-size: 32px;
            line-height: 33px;
            padding: 0;
            padding-bottom: 25px;
            margin-bottom: 23px;
            letter-spacing: -2px;
          }
          &-wrapper {
            display: flex;
            &-desc {
              &:not(:last-child) {
                margin-right: 53px;
              }
              width: 30%;
              display: flex;
              align-items: baseline;
              position: relative;
              .number {
                position: absolute;
                left: -26px;
                top: 13px;
              }
              h3 {
                letter-spacing: -1.32px;
                font-weight: $medium;
              }
              span {
                margin-bottom: 100px;
              }
            }
          }
        }
      }
    }
    .industry-section {
      &-wrapper {
        padding-top: 117px;
        &-top {
          position: relative;
          margin-bottom: 64px;
          p {
            width: 70%;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
        .three-parg {
          h2 {
            font-size: 32px;
            line-height: 33px;
            padding: 0;
            padding-bottom: 25px;
            margin-bottom: 23px;
            letter-spacing: -2px;
          }
          &-wrapper {
            display: flex;
            &-desc {
              &:not(:last-child) {
                margin-right: 53px;
              }
              display: flex;
              align-items: baseline;
              position: relative;
              .number {
                position: absolute;
                left: -26px;
                top: 13px;
              }
            }
          }
        }
      }
    }
    .frontier-markets-section {
      background-position: -20% 30%;
      height: 659px;
      background-repeat: no-repeat;
      padding-top: 120px;
      section {
        background: linear-gradient(
          202deg,
          rgba(0, 0, 0, 0.05) 0%,
          transparent 28%
        );
      }
      &-wrapper {
        display: flex;
        justify-content: flex-end;
        &-desc {
          width: 65%;
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            h1 {
              font-size: 78px;
              line-height: 79px;
            }
            h2 {
              font-size: 58px;
              line-height: 79px;
              letter-spacing: -3.77px;
            }
          }
          a {
            padding-bottom: 40px;
          }
        }
      }
    }
    .serve-section {
      &-wrapper {
        span {
          h1 {
            font-size: 112px;
            line-height: 112px;
            letter-spacing: -7px;
          }
        }
        > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        &-desc {
          width: 30%;
        }
      }
    }
    .team-section {
      height: 630px;
      border-bottom: none;
      &-wrapper {
        width: 941px !important;
        padding: 0;
        height: 100%;
        margin: 0 auto;
        span {
          display: unset;
          width: unset;
          margin-right: unset;
          margin-left: unset;
          h1 {
            font-size: 89px;
            line-height: 68px;
            letter-spacing: -4.45px;
            margin: 0;
            margin-bottom: 10px;
            padding-top: 110px;
            font-weight: $medium;
          }
          h2 {
            font-size: 46px;
            line-height: 45px;
            margin: 0;
            font-weight: $medium;
            letter-spacing: -2.3px;
          }
        }
        a {
          font-size: 22px;
          margin-top: 46px;
          justify-content: center;
          p {
            margin-right: 25px;
            margin-bottom: 0;
            margin-top: 0;
          }
          img {
            width: 5px;
            height: 14px;
          }
        }
      }
    }
    .routes {
      > div {
        width: 1200px;
        display: flex;
        justify-content: space-between;
      }
      &-investments-btn {
        margin: 0;
        font-size: 57px;
        padding: 50px 0;
        font-family: 'Gordita';
        font-weight: 500;
        letter-spacing: -2px;
        img {
          width: 11px;
          height: 31px;
        }
      }
      &-monsoon-tech-btn {
        font-size: 57px;
        margin: 0;
        padding: 50px 0;
        max-width: unset;
        font-family: 'Gordita';
        font-weight: 500;
        letter-spacing: -2px;
        img {
          width: 11px;
          height: 31px;
        }
      }
    }
  }
}
