.investments-page {
  min-height: 100vh;
  &-title {
    text-align: center;
    margin: 58px auto;
    max-width: 295px;
    h1 {
      font-size: 58px;
      line-height: 58px;
      letter-spacing: -3.77px;
      font-weight: $medium;
      margin: 0;
    }
    p {
      font-size: 20px;
      font-weight: $medium;
      line-height: 28px;
      margin: 0;
      margin-top: 37px;
    }
  }
  &-filters {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .filter-type {
        display: flex;
        padding-bottom: 16px;
        width: 70%;
        &-item {
          display: block;
          text-align: center;
          width: 30%;
          font-size: 14px;
          font-family: $familyG;
          line-height: 18px;
          letter-spacing: 0.35px;
          color: $black;
          opacity: 0.22;
          font-weight: 500;
          text-transform: capitalize;
          &:not(:last-child) {
            margin-right: 30px;
          }
          &.active {
            opacity: 1;
          }
        }
      }
      .filter-details {
        display: flex;
        border-top: 2px solid $grey;
        .filter-dropdown {
          &:not(:last-child) {
            margin-right: 40px;
          }
          &-title {
            display: flex;
            font-size: 14px;
            img {
              margin-right: 7px;
            }
          }
          &-items {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  .company-section {
    display: flex;
    flex-wrap: wrap;
    &-item {
      margin: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
      }
    }
    &-desc {
      display: none;
    }
  }
  .routes {
    background-color: $darkGreen;
    width: unset;
    &-about-btn {
      font-size: 35px;
      padding: 38px 0;
      max-width: 295px;
      border-bottom: solid black 1px;
      margin: 0 auto;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-decoration: none;
      p {
        margin: 0;
        position: relative;
        margin-right: 25px;
      }

      img {
        width: 8px;
        height: 22px;
      }
    }
    &-monsoon-tech-btn,
    &-investments-tech-btn {
      margin: 0;
      font-size: 35px;
      padding: 38px 0;
      max-width: 295px;
      margin: 0 auto;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-decoration: none;
      p {
        margin: 0;
        position: relative;
        margin-right: 25px;
      }

      img {
        width: 8px;
        height: 22px;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .investments-page {
    &-title {
      text-align: center;
      margin: 55px auto 78px;
      max-width: 941px;
      h1 {
        font-size: 70px;
        line-height: 66px;
        letter-spacing: -3.01px;
        font-weight: $medium;
        margin: 0;
      }
      p {
        font-size: 20px;
        font-weight: $medium;
        line-height: 26px;
        margin: 0;
        margin-top: 27px;
      }
    }
    &-filters {
      &-wrapper {
        flex-direction: row;
        justify-content: space-between;
        .filter-type {
          width: unset;
          padding: 0;
        }
        .filter-details {
          border: none;
          .filter-dropdown {
            position: relative;
            text-transform: capitalize;
            &:not(:last-child) {
              margin: 0;
              padding-right: 85px;
            }
            &-title {
              padding-left: 15px;
              img {
                margin-right: 11px;
              }
            }
            &-items {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 2;
              background-color: white;
              padding: 0px;
              border-radius: 4px;
              box-shadow: 6px 0 11px rgba(0, 0, 0, 0.16);
              .filter-dropdown-item {
                padding: 0 40px;
                white-space: nowrap;
                &:last-child {
                  padding-bottom: 25px;
                }
              }
            }
          }
        }
      }
    }
    .company-section {
      display: flex;
      &-item {
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        &:hover {
          .company-section-desc {
            opacity: 1;
            &-text {
              transform: scale(1);
            }
          }
        }
      }
      &-desc {
        opacity: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: opacity 0.3s ease-in;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: white;
        img {
          width: 35%;
          padding-top: 13px;
        }
        &-text {
          text-align: center;
          font-family: $familyG;
          font-size: 28px;
          font-weight: $medium;
          letter-spacing: -1.2px;
          line-height: 29px;
          width: 90%;
          transition: transform 0.5s ease-in-out;
          transform: scale(1.2);
        }
        .invest-date {
          font-family: $familyG;
          font-size: 18px;
          font-weight: $medium;
        }
      }
    }
    .routes {
      > div {
        width: 1200px;
        display: flex;
        justify-content: space-between;
      }
      &-about-btn {
        margin: 0;
        font-size: 57px;
        padding: 50px 0;
        img {
          width: 11px;
          height: 31px;
        }
      }
      &-monsoon-tech-btn,
      &-investments-tech-btn {
        font-size: 57px;
        margin: 0;
        padding: 50px 0;
        max-width: unset;
        img {
          width: 11px;
          height: 31px;
        }
      }
    }
  }
}
