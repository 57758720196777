.team-popup-page {
  position: fixed;
  opacity: 0.97;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 100;
  padding-top: 180px;
  box-sizing: border-box;
  .desc-section {
    .desc-img {
      max-height: 280px;
      overflow: hidden;
      background: linear-gradient(0deg, #eeeeee 0%, #fff 50%);
      img {
        width: 100%;
      }
    }
    .desc-wrapper-down-wrapper {
      font-family: $familyG;
      .border-seperation {
        height: 6px;
        width: 100%;
        display: block;
        background: $green;
      }
      .desc-wrapper-down {
        margin-top: 45px;
        margin-bottom: 61px;
        text-align: center;
        &-title {
          width: 80%;
          font-size: 51px;
          margin: 0 auto 24px;
          line-height: 44px;
          letter-spacing: -2.55px;
          font-weight: $medium;
        }
        &-content {
          margin-top: 0;
          margin-bottom: 0;
          p {
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          color: $green;
          line-height: 33px;
          font-size: 23px;
          font-weight: $medium;
          letter-spacing: -1.15px;
        }
      }
    }
    .desc-wrapper {
      &-navigation {
        box-sizing: border-box;
        position: absolute;
        z-index: 1;
        top: 130px;
        left: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding-left: 40px;
        padding-right: 40px;
        img {
          cursor: pointer;
        }
        &-arrows {
          img:first-child {
            margin-right: 40px;
          }
        }
      }
      &-content {
        text-align: center;
        > * {
          display: block;
        }
        &-name {
          font-size: 50px;
          line-height: 47px;
          width: 90%;
          letter-spacing: -2.5px;
          margin-bottom: 19px;
          margin-top: 25px;
        }
        &-role {
          text-transform: uppercase;
          letter-spacing: 9px;
          line-height: 19px;
          font-size: 16px;
          font-weight: $regular;
          font-family: $familyG;
          margin-top: 0;
          margin-bottom: 28px;
        }
        &-socialmedia {
          margin: 0 auto 45px;
        }
        &-desc {
          font-family: $familyB;
          font-weight: $light;
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 70px;
          margin-top: 0px;
          p {
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .team-popup-page {
    padding-top: 100px;
    .desc-section {
      section > div {
        width: auto;
        margin-right: unset;
        margin-left: unset;
      }
      .desc-wrapper-down-wrapper {
        .border-seperation {
          display: none;
        }
        .desc-wrapper-down {
          width: 941px;
          margin-right: auto;
          margin-left: auto;
          margin-top: 69px;
          margin-bottom: 76px;
          text-align: left;
          &-title {
            text-align: right;
            width: 100%;
            font-size: 61px;
            margin: unset;
            line-height: 61px;
            letter-spacing: -3.05px;
          }
          &-content {
            display: flex;
            justify-content: flex-end;
            margin-top: 22px;
            > * {
              width: 48%;
            }
          }
        }
      }
      .desc-section-upper {
        background: linear-gradient(0deg, #eeeeee 0%, #fff 50%);
        &-content {
          width: 1000px;
          margin-right: auto;
          margin-left: auto;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: space-between;
          flex-direction: row-reverse;
        }
        .desc-img {
          width: 45%;
          max-height: unset;
          overflow: visible;
          background: unset;
          > div {
            display: flex;
          }
        }
        .desc-wrapper {
          width: 55%;
          &-navigation {
            position: unset;
            z-index: 1;
            top: unset;
            left: unset;
            display: flex;
            width: 172px;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 47px;
          }
          &-content {
            text-align: left;
            display: flex;
            flex-direction: column;
            &-name {
              font-size: 61px;
              line-height: 73px;
              width: 90%;
              letter-spacing: -3.05px;
              margin-bottom: 13px;
              margin-top: 0;
            }
            &-role {
              letter-spacing: 9px;
              line-height: 19px;
              font-size: 16px;
              margin-bottom: 47px;
            }
            .social-media-link {
              order: 1;
            }
            &-socialmedia {
              margin: 0 auto 67px;
            }
            &-desc {
              margin-bottom: 30px;
            }
          }
          .border-seperation {
            display: none;
          }
          &-down {
            margin-top: 45px;
            margin-bottom: 61px;
            text-align: center;
            &-title {
              width: 80%;
              font-size: 51px;
              margin: 0 auto 24px;
              line-height: 44px;
              letter-spacing: -2.55px;
              font-weight: $medium;
            }
            &-content {
              margin-top: 0;
              margin-bottom: 0;
              p {
                &:first-child {
                  margin-top: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
              color: $green;
              line-height: 33px;
              font-size: 23px;
              font-weight: $medium;
              letter-spacing: -1.15px;
            }
          }
        }
      }
    }

    &.popup-with-no-image {
      .desc-section {
        &-upper {
          .desc-wrapper {
            width: 100%;
          }
          .desc-wrapper-content-desc {
            column-count: 2;
            column-gap: 55px;
          }
        }
      }
    }
  }
}
