.investment-popup-page {
  position: fixed;
  width: 100%;
  height: 100%;
  /*opacity: 0.97;*/
  background: rgba(255, 255, 255, 0.97);
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 100;
  padding-top: 100px;
  box-sizing: border-box;
  &-desktop {
    display: none;
    .investment-popup-page-wrapper {
      display: flex;
      justify-content: flex-end;
      .title {
        display: flex;
        margin-bottom: 26px;
        width: calc(
          100% - 324px - 40px
        ); // 40px margin   324px is size of image
        justify-content: space-between;
        > img {
          margin-top: 30px;
        }
        .navigation {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          > img {
            cursor: pointer;
          }
          &-arrows {
            display: flex;
            align-items: center;
            margin-right: 40px;
            > img {
              cursor: pointer;
              &:first-child {
                margin-right: 40px;
              }
            }
          }
        }
      }
    }
    .desc-section {
      background: linear-gradient(180deg, #eeeeee 0%, #fff 50%);
      .desc {
        display: flex;
        &-img {
          img {
            position: relative;
            bottom: 85px;
            width: 324px;
            border-radius: 4px;
          }
        }
        &-wrapper {
          margin-left: 40px;
          &-name {
            margin: 0;
            margin-top: 20px;
            font-size: 15px;
            line-height: 30px;
            font-weight: $medium;
            font-family: $familyG;
            letter-spacing: -0.38px;
          }
          &-title {
            margin-top: 0;
            margin-bottom: 26px;
            font-size: 32px;
            line-height: 33px;
            font-weight: $medium;
            font-family: $familyG;
            letter-spacing: -1.42px;
          }
          &-text {
            margin-bottom: 0;
            padding-bottom: 40px;
            margin-top: 0;
            font-weight: $light;
            font-size: 15px;
            line-height: 20px;
          }
        }
        &-details {
          display: flex;
          justify-content: space-between;
          margin-bottom: 70px;
          &-item {
            border-top: 1px $grey solid;
            margin-bottom: 30px;
            &.industry,
            &.founders {
              width: 14%;
            }
            &.milestones,
            &.jobs {
              width: 27%;
            }
            h2 {
              font-weight: $medium;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 13px;
              margin-top: 3px;
            }
            p {
              font-size: 13px;
              font-weight: $light;
              line-height: 18px;
              margin: 0;
            }
            a {
              display: inline-block;
              text-decoration: none;
              color: $black;
              border-bottom: 1px solid $grey;
              &:hover {
                border-color: $orange;
                color: $orange;
              }
            }
          }
        }
      }
    }
    .routes {
      > div {
        justify-content: center;
        .routes-about-btn {
          max-width: 1000px;
        }
      }
    }
  }
  &-mobile {
    .investment-popup-page-wrapper {
      .title {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 26px;
        > img {
          margin-top: 30px;
        }
        .navigation {
          margin-top: 40px;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          > img {
            cursor: pointer;
          }
          &-arrows {
            display: flex;
            align-items: center;
            > img {
              cursor: pointer;
              &:first-child {
                margin-right: 40px;
              }
            }
          }
        }
      }
    }

    .desc-section {
      background: linear-gradient(180deg, #eeeeee 0%, #fff 50%);
      .desc {
        &-img {
          margin: 0;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            z-index: 0;
            background-color: $white;
            height: 50%;
          }
          img {
            position: relative;
            z-index: 1;
            width: 295px;
          }
        }
        &-wrapper {
          text-align: center;
          &-name {
            margin-top: 31px;
            margin-bottom: 12px;
            font-size: 19px;
            line-height: 30px;
            font-weight: $medium;
            font-family: $familyG;
            letter-spacing: -0.47px;
          }
          &-title {
            margin-top: 0;
            margin-bottom: 26px;
            font-size: 32px;
            line-height: 33px;
            font-weight: $medium;
            font-family: $familyG;
            letter-spacing: -1.38px;
          }
          &-text {
            margin-bottom: 0;
            padding-bottom: 70px;
            margin-top: 0;
            font-weight: $light;
            font-size: 15px;
            line-height: 20px;
          }
        }
        &-details {
          text-align: center;
          margin: 0;
          margin-bottom: 70px;
          width: 100%;
          &-item {
            border-top: 1px $grey solid;
            margin-bottom: 30px;
            h2 {
              font-weight: $medium;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 13px;
              margin-top: 3px;
            }
            p {
              font-size: 13px;
              font-weight: $light;
              line-height: 18px;
              margin: 0;
            }
            a {
              display: inline-block;
              text-decoration: none;
              color: $black;
              border-bottom: 1px solid $grey;
              &:hover {
                border-color: $orange;
                color: $orange;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .investment-popup-page {
    &-mobile {
      display: none;
    }
    &-desktop {
      display: block;
    }
  }
}
