@font-face {
  font-family: 'Bahnschrift';
  src: url('./assets/fonts/Bahnschrift.ttf');
  font-weight: 100 700;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Thin.otf');
  font-weight: 100;
}
@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Thin\ Italic.otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Light.otf');
  font-weight: 200;
}
@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Light\ Italic.otf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Regular\ Italic.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Medium.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Medium\ Italic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Bold.otf');
  font-weight: 700;
}
@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Bold\ Italic.otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Ultra.otf');
  font-weight: 800;
}
@font-face {
  font-family: 'Gordita';
  src: url('./assets/fonts/gordita/Gordita\ Ultra\ Italic.otf');
  font-weight: 800;
  font-style: italic;
}

@import './assets/scss/variables.scss';
@import './assets/scss/main.scss';
@import './assets/scss/header.scss';
@import './assets/scss/home.scss';
@import './assets/scss/contact.scss';
@import './assets/scss/about.scss';
@import './assets/scss/jobs.scss';
@import './assets/scss/investments.scss';
@import './assets/scss/investmentPopUp.scss';
@import './assets/scss/team.scss';
@import './assets/scss/teamPopup.scss';
@import './assets/scss/monsoon.scss';
@import './assets/scss/footer.scss';
