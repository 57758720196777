.contact-page {
  min-height: 100vh;
  &-wrapper {
    h1 {
      font-size: 61px;
      line-height: 52px;
      font-weight: $medium;
      letter-spacing: -3.05px;
      margin-top: 83px;
      margin-bottom: 50px;
      text-align: center;
    }
    .contact-form {
      .MuiFormControl-root {
        width: 100%;
        margin-bottom: 13px;
      }
      .file-uploader {
        position: relative;
        [type='file'] {
          opacity: 0;
        }

        .input-file-title {
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          color: #b3b3b3;
        }

        .file-uploaded {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            width: 18px;
            height: 18px;
            border: 1px solid transparent;
            border-radius: 50%;
            background: $grey;
            display: inline-flex;
            justify-content: center;
            line-height: 14px;
          }
        }
      }
      textarea {
        width: 100%;
        border: none;
        border-bottom: 1.5px solid grey;
        min-height: 120px;
        font-size: 15px;
        outline: none;
        &.error {
          border-color: #f44336;
          border-width: 2px;
        }
      }
    }
    .submit-btn {
      margin-top: 43px;
      font-size: 22px;
      min-height: 40px;
      color: $green;
      display: flex;
      justify-content: center;
      align-items: baseline;
      padding-bottom: 90px;
      text-decoration: none;
      p {
        margin: 0;
        position: relative;
        margin-right: 15px;
        font-size: 22px;
        &:after {
          width: 100%;
          content: '';
          position: absolute;
          height: 2px;
          background-color: $grey;
          transition: background-color 0.3s ease, bottom 0.3s ease;
          bottom: -10px;
          left: 0;
        }
      }
      &:hover {
        p {
          &:after {
            background-color: $green;
            bottom: -2px;
          }
        }
      }
      img {
        width: 5px;
        height: 14px;
      }
    }
  }
  .routes {
    background-color: $darkGreen;
    width: unset;
    &-about-btn {
      font-size: 35px;
      padding: 38px 0;
      max-width: 295px;
      border-bottom: solid black 1px;
      margin: 0 auto;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-decoration: none;
      p {
        margin: 0;
        position: relative;
        margin-right: 25px;
      }

      img {
        width: 8px;
        height: 22px;
      }
    }
    &-investments-btn {
      margin: 0;
      font-size: 35px;
      padding: 38px 0;
      max-width: 295px;
      margin: 0 auto;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-decoration: none;
      p {
        margin: 0;
        position: relative;
        margin-right: 25px;
      }

      img {
        width: 8px;
        height: 22px;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .contact-page + .footer {
    .footer-contact {
      display: none;
    }
    .footer-links {
      margin: 0;
    }
  }
  .contact-page {
    &-wrapper {
      width: 580px !important;
      h1 {
        font-size: 66px;
        line-height: 84px;
        letter-spacing: -3.3px;
      }
      .contact-form {
        width: 500px;
        margin: 0 auto;
      }
    }
    .routes {
      > div {
        width: 1200px;
        display: flex;
        justify-content: space-between;
      }
      &-about-btn {
        margin: 0;
        font-size: 57px;
        padding: 50px 0;
        img {
          width: 11px;
          height: 31px;
        }
      }
      &-investments-btn {
        font-size: 57px;
        margin: 0;
        padding: 50px 0;
        img {
          width: 11px;
          height: 31px;
        }
      }
    }
  }
}
