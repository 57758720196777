$mobile: 940px;
$desktop: 941px;
$largeDesk: 1720px;

$black: #000;
$light-green: #00c099;
$green: #008f71;
$darkGreen: #00614d;
$orange: #ffc8ac;
$grey: #e3e3e3;
$light-grey: #b3b1b1;
$white: #ffffff;

$thin: 100;
$light: 200;
$regular: 400;
$medium: 500;
$bold: 700;

$familyB: 'Bahnschrift', 'sans-serif';
$familyG: 'Gordita', 'sans-serif';
