.team-page{
    &-title{
        text-align: center;
        margin: 58px auto 28px;
        max-width: 295px;
        h1{
            font-size: 45px;
            line-height: 42px;
            letter-spacing: -2.92px;
            font-weight: $medium;
            margin: 0;
        }
        >p{
            font-size: 20px;
            line-height: 28px;
            margin: 0;
            margin-top: 28px;
        }
    }
    min-height: 100vh;
    &-content{
        &-upper{
            width: 100%;
            margin: 0;
            margin-bottom: 100px;
            &-member{
                &-item{
                    &-image{
                        background: linear-gradient(1deg, rgba(0, 0, 0, 0.04) 0%, white 34%);
                        display: flex;
                        img{
                            width: 295px;
                            margin: 0 auto;
                        }
                    }
                    &-details{
                        margin-top: 20px;
                        margin-bottom: 50px;
                        text-align: center;
                        h2{
                            margin: 0;
                            font-size: 20px;
                            line-height: 24px;
                            letter-spacing: -0.86px;
                            font-weight: $medium;
                        }
                        p{
                            margin: 0;
                            font-size: 15px;
                            line-height: 21px;
                            color: #848484;
                        }
                    }
                }
            }
            &-images{
                img{ 
                    width: 100%;
                }
            }
        }
        &-lower{
            text-align: center;
            &-venture{
                &-title{
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: -0.86px;
                    margin: 0;
                    padding-bottom: 10px;
                    border-bottom: 2px solid $grey;
                }
                &-items{
                    margin: 17px 0 80px;
                }
            }
            &-nonexecutive{
                &-title{
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: -0.86px;
                    margin: 0;
                    padding-bottom: 10px;
                    border-bottom: 2px solid $grey;
                }
                &-items{
                    margin: 17px 0 80px;
                }
            }
        }
    }
    
    .routes{
        background-color: $darkGreen;
        width: unset;
        &-about-btn{
            font-size: 35px;
            padding: 38px 0;
            max-width: 295px;
            border-bottom: solid black 1px;
            margin: 0 auto;
            color:$white;
            display: flex;
            justify-content: center;
            align-items: baseline;
            text-decoration: none;
            p{
                margin: 0;
                position: relative;
                margin-right: 25px;
            }
            
            img{
                width: 8px;
                height: 22px;
            }
        }
        &-monsoon-tech-btn, &-investments-tech-btn{
            margin: 0;
            font-size: 35px;
            padding: 38px 0;
            max-width: 295px;
            margin: 0 auto;
            color:$white;
            display: flex;
            justify-content: center;
            align-items: baseline;
            text-decoration: none;
            p{
                margin: 0;
                position: relative;
                margin-right: 25px;
            }
            
            img{
                width: 8px;
                height: 22px;
            }
        }
    }
}

@media only screen and (min-width: $desktop) {
    .team-page{
        &-title{
            text-align: center;
            margin: 61px auto 39px;
            max-width: 941px;
            h1{
                font-size: 64px;
                line-height: 61px;
                letter-spacing: -3.2px;
                font-weight: $medium;
                margin: 0;
                margin-bottom: 15px;
            }
            p{
                font-size: 20px;
                line-height: 26px;
                margin: 0 auto;
                width: 80%;
                font-weight: $light;
            }
        }

        &-content{
            &-upper{
                &-images{
                    background: linear-gradient(1deg, rgba(0, 0, 0, 0.04) 0%, white 34%);
                    &-wrapper{
                        display: flex;
                        cursor: pointer;
                        justify-content: center;
                        
                        width: 941px !important;
                        margin: 0 auto;
                    }
                }
                &-content{
                    width: 941px !important;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    &-item{
                        margin-top: 20px;
                        h2{
                            font-size: 20px;
                            line-height: 24px;
                            letter-spacing: -0.86px;
                            margin: 0;
                            margin-bottom: 6px;
                        }
                        p{
                            font-size: 15px;
                            line-height: 21px;
                            color: #848484;
                            margin: 0;
                        }
                    }
                }
            }
            &-lower{
                display: flex;
                justify-content: center;
                &-venture{
                    width: 30%;
                    margin-right: 53px;
                }
                &-nonexecutive{
                    width: 30%;
                }
            }
        }
        
        .routes{
            >div{
                width: 1200px;
                display: flex;
                justify-content: space-between;
            }
            &-about-btn{
                margin: 0;
                font-size: 57px;
                padding: 50px 0;
                img{
                    width: 11px;
                    height: 31px;
                }
            }
            &-monsoon-tech-btn, &-investments-tech-btn{
                font-size: 57px;
                margin: 0;
                padding: 50px 0;
                max-width: unset;
                img{
                    width: 11px;
                    height: 31px;
                }
            }
        }
    }
}