.footer,
.monsoon-footer {
  max-width: 295px;
  margin: 0 auto;
  .monsoon-name {
    font-family: $familyG;
    font-size: 38px;
    padding: 10px 0;
    font-weight: $bold;
    text-align: center;
    background-image: linear-gradient(351deg, #00c08c 30%, #00785a 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    background-repeat: repeat;
  }
  &-wrapper {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    > a {
      display: none;
    }
  }
  &-contact {
    display: flex;
    flex-direction: column;
    p {
      margin: 0;
      font-size: 19px;
      line-height: 19px;
      margin-bottom: 13px;
    }
    &-cta {
      display: flex;
      text-decoration: none;
      color: $black;
      margin-bottom: 48px;
      h1 {
        margin: 0 18px 0 0;
        letter-spacing: -2.35px;
        font-size: 46px;
        line-height: 39px;
        font-weight: $medium;
        position: relative;
        &:after {
          width: 100%;
          content: '';
          position: absolute;
          background-color: $orange;
          transition: background-color 0.3s ease, bottom 0.3s ease;
          bottom: -20px;
          left: 0;
        }
        &:hover {
          &:after {
            height: 2px;
            bottom: -10px;
          }
        }
      }
    }
  }
  &-links {
    display: flex;
    margin-bottom: 57px;
    &-menu {
      font-weight: bold;
      .title {
        font-size: 17px;
        color: #d1d1d1;
      }
      .menu-item {
        font-size: 12px;
        text-decoration: none;
        color: $black;
      }
      &.left {
        margin-right: 40px;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .footer,
  .monsoon-footer {
    max-width: 1300px;
    padding-top: 90px;
    margin-left: auto;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    &-wrapper {
      justify-content: space-between;
      margin: 0;
      align-items: center;
      flex-direction: row;
      > a {
        display: block;
      }
    }
    .monsoon-name {
      margin: 0;
      font-size: 69px;
      padding: 25px 0;
      position: relative;
      bottom: 10px;
    }
    &-contact {
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
        margin-bottom: 14px;
        padding-left: 5px;
        font-weight: 100;
        font-size: 24px;
      }
      &-cta {
        display: flex;
        h1 {
          margin: 0;
          margin-right: 30px;
          font-size: 62px;
          line-height: 52px;
          letter-spacing: -3.1px;
        }
      }
    }
    &-links {
      display: flex;
      justify-content: space-between;
      &-menu {
        font-weight: bold;
        .title {
          font-size: 17px;
          font-family: $familyG;
          margin-top: 0;
        }
        .menu-item {
          font-size: 12px;
          letter-spacing: 1.8px;
          p {
            margin-bottom: 0;
            span {
              position: relative;
              &:after {
                width: 100%;
                content: '';
                position: absolute;
                background-color: $orange;
                transition: background-color 0.3s ease, bottom 0.3s ease;
                bottom: -10px;
                left: 0;
              }
              &:hover {
                &:after {
                  height: 2px;
                  bottom: -5px;
                }
              }
            }
          }
        }
        &.left {
          margin-right: 68px;
        }
      }
    }
    .trademark {
      opacity: 18%;
      font-size: 12px;
      margin-top: 56px;
      margin-bottom: 33px;
    }
  }
}
